
import { useEffect, useState } from "react";

import "./showEvalResponsableEmpStartNotif.scss";
import axios from "axios";
import { errorHandeling } from "../../errorHandeling";
import { useSelector } from "react-redux";
import MainPageNotifiEvalStart from "../MainPageNotifiEvalStart/MainPageNotifiEvalStart";
import { createSelector } from "reselect";

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);


function ShowEvalResponsableEmpStartNotif() {

    const token = useSelector(state => state.user.token)

    
   const userPermissions = useSelector(selectUserPermissions);
    
   //to show the evaluation nofication
   const [evalNotifiSemiAnnualStart,setEvalNotifiSemiAnnualStart] = useState(false);

   const [evalNotifiDirectManagerStart,setEvalNotifiDirectManagerStart] = useState(false);

   
   const [error,setError] = useState('');


      //check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    return false;
  }
  else{
    return true;
  }
};   




     //fetch the the records for the statistcs status
  const fetchEvalStatStatus = async () => {
    try {
      const response = await axios.get(
        `https://emp.almatingroup.com/api/V1/users/evaluation-users/all`,
     
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data);

      setEvalNotifiSemiAnnualStart(response.data?.data?.SEMI_ANNUAL);

      setEvalNotifiDirectManagerStart(response.data?.data?.manager_evaluation);

 
    } catch (error) {
      console.error('Error:', error);
      setError(errorHandeling(error.message))
  
    }

  
  };  


  useEffect(()=>{
    fetchEvalStatStatus();
  },[])


  return (
    <div className="eval_notifisStart_cover" >
    { //only the user with the create_evaluations permission should fire the evaluate system
        (evalNotifiSemiAnnualStart && checkPermissionAndThrow("create_evaluations") ) &&
        (
            <MainPageNotifiEvalStart 
            notifText={"حان موعد التقييم النصف سنوي اضغط ابدء للبدء و الانتقال الى صفحة الاحصائيات"}
            btnText = {"ابدء"}
            btnLink = {"https://emp.almatingroup.com/api/V1/evaluations/start-all"}
            navLink = {"/emp/eval_stat_main_page_show"}
            commingError = {error}
            />
        )
    }

{//only the user with the create_manager_evaluations permission should fire the evaluate system
        (evalNotifiDirectManagerStart && checkPermissionAndThrow("create_manager_evaluations") ) &&
        (
            <MainPageNotifiEvalStart 
            notifText={"حان موعد التقييم السنوي للمدير المباشر اضغط ابدء للبدء و الانتقال الى صفحة الاحصائيات"}
            btnText = {"ابدء"}
            btnLink = {"https://emp.almatingroup.com/api/V1/manager-evaluations/start-all"}
            navLink = {"/emp/eval_directManager_stat"}
            commingError = {error}
            />
        )
    }

    </div>
  )
}

export default ShowEvalResponsableEmpStartNotif