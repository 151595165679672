import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
//to support old versions of browsers
// import AbortController from 'abort-controller';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';

import 'whatwg-fetch';
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './store/store';
import AppRouter from './Routes/AppRouter';



// if (!window.AbortController) {
//   window.AbortController = AbortController;
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>

      <App />

  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
