import { useEffect, useState } from "react";
import "./evalDirectManagerByEmps.scss";
import GeneralPopup from "../../../../components/GeneralPopup/GeneralPopup";
import LottieHandler from "../../../../components/LottieHandler/LottieHandler";
import axios from "axios";
import { useSelector } from "react-redux";
import { errorHandeling } from "../../../../errorHandeling";
import { responseLabelsArr,labelsArr,descArr } from "../../../../libs/arrEvalDirectManager";
import { useNavigate } from "react-router-dom";

function EvalDirectManagerByEmps() {

    const token = useSelector(state => state.user.token)  

    let navigate = useNavigate();
  
    const [numValue,setNumValue] = useState(['','','','','','','','','','']);

    const [evalId,setEvalId] = useState(null);

    const [loading,setLoading] = useState(false);

    const [loadingResult,setLoadingResult] = useState(false);
    
    //error to handle the errors related to the logic I mean if the number out of range or something like that 
    const [errorHandlingLogic,setErrorHandlingLogic] = useState(null);

    const[error,setError] = useState(null);

  

    const handleChangeNumValue = (e,index)=>{
      let value = parseInt(e.target.value, 10); 

      if (isNaN(value))
        {value = ''};

        let updateValues = [...numValue];

        updateValues[index] = value;

        setErrorHandlingLogic(null);

        for(let i=0;i<10;i++){
          if( updateValues[i]!== '' && (updateValues[i] > 10 || updateValues[i] < 1) ){
            setErrorHandlingLogic("*يرجى ادخال قيمة بين ال 1 و ال 10");
          }
        }

        setNumValue(updateValues);

    }

  //some functions to check the situation of the fields before submitting

  const handleNumOutOfTheRange = (event, maxRange,minRange) => {
    const inputElement = event.target;
    const value = parseFloat(inputElement.value);

    if (value > maxRange || value < minRange ) {
      inputElement.classList.add('danger_outOfRange');
    } else {
      inputElement.classList.remove('danger_outOfRange');
    }
  };


  const handleCheckIfAllDataExist = ()=>{
    for(let i=0;i<10;i++){
      if(numValue[i] === '' ){
        setError("*يرجى التأكد من ملئ جميع الحقول قبل الحفظ");
        return false;
      }
    }
    return true;
  }

    useEffect(()=>{
    console.log("the num values are :",numValue)
    },[numValue])


  //fetch the data for the evaluation
 const fetchEvalouationData = async () => {

  try {
    setLoading(true);

    const response = await axios.get(
      `https://emp.almatingroup.com/api/V1/manager-evaluations/should-evaluate-my-manager`,
   
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    console.log('Response:', response.data.data);
    
    setEvalId(response?.data?.data?.evaluate_my_manager);

    setLoading(false);

  } catch (error) {
    console.error('Error:', error);
    setLoading(false);
    setError(errorHandeling(error))

  }


};

//the submit function

const handleSubmit = async (e)=>{
 e.preventDefault();
 
 let data = {};


 if( handleCheckIfAllDataExist() ){

  for(let i = 0; i<10 ; i++){
    data[responseLabelsArr[i]] = numValue[i];
   }
  
   console.log("the data for the submiting is :",data);
  
  
   try {
    setLoadingResult(true)
  
    const response = await axios.put(
      `https://emp.almatingroup.com/api/V1/manager-evaluations/update/${evalId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    console.log('Response after submitting:', response.data);
    setLoadingResult(false)
    // setSaveChangesSuccessfully(true)
    
    navigate("/emp", { state: { successMessage: "لقد تم تقييم المدير المباشر بنجاح" } });

  } catch (error) {
    console.error('Error:', error);
    setLoadingResult(false);
    // setSaveChangesSuccessfully(false)
    setError(errorHandeling(error));
  }
  
 }



}


useEffect(()=>{
  fetchEvalouationData();
},[])



    return (
    <div className="EvalDirectManagerByEmps">

      {
        ( loading ) 
        ?
        (
        <div className="EvalDirectManagerByEmps_loading_container">
        <LottieHandler type="kite" message="يتم التحميل ..." />
        </div>)
        :
        (
          <div className="EvalDirectManagerByEmps_container">
          <span className="EvalDirectManagerByEmps_header">تقييم المسؤول المباشر</span>
      
          <span className="EvalDirectManagerByEmps_note" dir="rtl"><span className="EvalDirectManagerByEmps_note_star">*</span>جميع القيم هي بين ال 1 و ال 10</span>

      <div className="EvalDirectManagerByEmps_items">
  
       {
        labelsArr.map((item,index)=>(
        
          
          <div className="EvalDirectManagerByEmps_item" key={index} >
            <div className="EvalDirectManagerByEmps_item_left">
            <input type="number" min={0} max={10} 
              className="EvalDirectManagerByEmps_item_input"
              value={numValue[index]}
              onInput={(e) => handleNumOutOfTheRange(e,10,1)}
              onChange={(e)=>{handleChangeNumValue(e,index)}}
              />
            </div>
  
            <div className="EvalDirectManagerByEmps_item_right">
             <span className="EvalDirectManagerByEmps_item_label" dir="rtl">
                 <span className="EvalDirectManagerByEmps_item_label_num" dir="rtl">{index+1}-</span>
                <GeneralPopup reason={descArr[index]} firstWord = {item}/>
              </span>
              </div>
  
          </div>
        ))
       }
  
     {
     (error) &&
    (<span style={{color:"red",fontSize:"1.1rem",marginTop:"1rem"}} dir="rtl">
      {error}
      </span>)
      }


{
     (errorHandlingLogic) &&
    (<span style={{color:"red",fontSize:"1.1rem",marginTop:"1rem"}} dir="rtl">
      {errorHandlingLogic}
      </span>)
      } 


      {
        (errorHandlingLogic) 
        ?
        (<></>)
        :
        (
          <button className="EvalDirectManagerByEmps_btn" 
          onClick={(e)=>{handleSubmit(e)}}
          disabled={errorHandlingLogic ?  true :  false }
          dir="rtl"
          >
            {
              (loadingResult)
              ?
              "يرجى الانتظار..."
              :
              "حفظ"
            } 
            </button> 
        )
      }
   
  
  
  </div>
  
  
          </div>
        )
      }


    </div>
  )
}

export default EvalDirectManagerByEmps