import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { fetchAuthMe } from './store/AuthmeSlice/authmeSlice';


const selectUserPermissions = createSelector(
    state => {
      const roles = state.authme.data?.roles;
      if (!roles || roles.length === 0 || !roles[0].permessions) {
        return null;
      }
      return roles[0].permessions;
    },
    permessions => permessions ? permessions.map(perm => perm.name) : []
  );
  
  const hasAnyPermission = (userPermissions, requiredPermissions) => {
    return requiredPermissions.some(permission => userPermissions.includes(permission));
  };


  const IsManagerOrHasPermissionWrapper = ({requiredPermissions = [],children }) => {
    // const isManager = useSelector(selectUserIsManager);

    const userPermissions = useSelector(selectUserPermissions);

    const dispatch = useDispatch();
 
    const { data, status, error } = useSelector((state) => state.authme);
  
    const token = useSelector(state => state.user.token)
  
    const [loading, setLoading] = useState(false);


  //////

 
  useEffect(() => {

    if(status === 'idle' && token){
      dispatch(fetchAuthMe())
      setLoading(true)
    }
    else if (status === 'loading') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  
  }, [status]);

  if (loading) {
    console.log('Loading from CheckPrivateRouteAuth' ,status);
    return <div  
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

         }}>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
    </div>;
  }

  else if (error || !token ) {
    console.log("the status is :",status)
    console.log("the data is :",data)

    console.log('Authentication error or no data, redirecting to login');
    return <Navigate to="/login" />;
  }



  /////
    
  else if (data?.is_manager || hasAnyPermission(userPermissions, requiredPermissions)) {

      return children;

    }
  
    else{
      console.log("the user is not manager or not has the required permissions")
      console.log("the data is :",data)
      console.log("the status is :",status)




      // return <>not autherized</>;
      return <Navigate to="/not_authorized" />;
    }

  };
  
  export default IsManagerOrHasPermissionWrapper;