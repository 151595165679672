import Lottie from "lottie-react";

import kite from "../../LottieFiles/kite.json"
import archive from "../../LottieFiles/archive.json"
import permissions from "../../LottieFiles/permissions.json"
import "./lottieHandler.scss"
const lottieFilesMap = {
  kite ,
  archive,
  permissions
};



const LottieHandler = ({ type, message, className }) => {
  const lottie = lottieFilesMap[type];
  const messageStyle =
    type === "error"
      ? { fontSize: "19px", color: "red" }
      : { fontSize: "19px", marginTop: "1rem" };

      console.log("the error is ",message)

  return (
    <div className={`lottieDiv ${className}`} style={{marginTop:"-1rem"}}>
      <Lottie animationData={lottie} style={{ width: "400px",height:"15rem" }} />
      {message && <h3 style={messageStyle} dir="rtl">{message}</h3>}
    </div>
  );
};

export default LottieHandler;