export const responseLabelsArr = ["first_mark","second_mark",
    "third_mark","fourth_mark","fifth_mark",
    "sixth_mark","seventh_mark","eighth_mark",
   "ninth_mark","tenth_mark"]; 

export const labelsArr = ["القيادة و التوجيه","التواصل الفعال",
"حل المشكلات","ادارة النزاعات","العدالة و الانصاف","الدعم و التشجيع"
,"بناء علاقات عمل ايجابية","الاستماع و التفهم","تطوير الفريق",
"تحفيز الفريق"
];


export const descArr = [
"قدرة المدير أو رئيس القسم على قيادة الفريق , و توجيه الأفراد نحو تحقيق الأهداف المشتركة"
,"قدرة المدير او رئيس القسم على التواصل بوضوح و فعالية مع الفريق"
,"قدرة المدير او رئيس القسم على التعامل مع المشكلات , و اتخاذ الاجراءات المناسبة لحلها بسرعة فعالية"
,"قدرة المدير او رئيس القسم على ادارة و حل النزاعات بين أعضاء الفريق"
,"هل يعامل المدير او رئيس القسم جميع الموظفين بعدالة و انصاف و هل يتجنب التحيز و الميول الشخصية"
,"هل يوفر المدير او رئيس القسم الدعم و التشجيع للموظفين و عل يعترف بجهودهم و انجازاتهم"
,"هل يساهم المدير او رئيس القسم في بناء علاقات عمل ايجابية بين أعضاء الفريق ؟ و هل يشجع على التعاون و العمل الجماعي ؟"
,"قدرة المدير او رئيس القسم على الاستماع الجيد و تفهم مشكلات الفريق"
,"قدرة المدير على دعم و تطوير الموظفين التابعين له من خلال التدريب , الارشاد , و فرص تطوير المهارات"
,"قدرة المدير او رئيس القسم على تحفيز الفريق و الحفاظ على روح العمل الجماعي"
];