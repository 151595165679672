
import { useState } from "react";
import "./mainPageNotifiEvalStart.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { errorHandeling } from "../../errorHandeling";

function MainPageNotifiEvalStart({notifText,btnText,btnLink,navLink,commingError}) {

    const token = useSelector(state => state.user.token)

    let navigate = useNavigate();

    const [error,setError] = useState(null);

    const [loading,setLoading] = useState(false);


  const handelClickingEvalStart = async()=> {
     
    try {
      setLoading(true);

      const response = await axios.post(
        btnLink,
        {

        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data);

      setLoading(false);
  
      navigate(navLink); // Then navigate to the stat page

    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      setError(errorHandeling(error))
    }

   };



  return (
    <div className="eval_start_notif_cover">
        {
            (error) &&
            (<span style={{color:"red"}}>{error}</span>)
        }
        
        {
            (commingError) &&
            (<span style={{color:"red"}}>{commingError}</span>)
        }

        <span className="eval_start_notif_text" dir="rtl">{notifText}</span>
        
        <div className="eval_start_btn_cover">
        <button className="eval_start_notif_btn" dir="rtl" onClick={()=>{handelClickingEvalStart()}}>
            {
              (loading) ?
              ("يرجى الانتظار...")  
              :
              (
                btnText
              )
            }
            
        </button>
        </div>
    </div>
  )
}

export default MainPageNotifiEvalStart