import React, { useState } from 'react'
import Footer from '../components/Footer/Footer'
import styles from './mainLayout.module.scss'
import { Outlet, useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar/Navbar'

function MainLayout() {
  const [key, setKey] = useState(Date.now());
  const navigate = useNavigate();

  const handleRefresh = () => {
    setKey(Date.now());  // Change the key to trigger re-mount
    navigate(window.location.pathname); // Re-navigate to the current path
  };
  return (
    <div className={styles.MainLayout}>
      <Navbar onIconClick={handleRefresh} /> {/* Pass handleRefresh to Navbar */}
         
         <div className={styles.wrapper}>
         <Outlet key={key} /> {/* Add the unique key to Outlet */}
         </div>

        
        <Footer/>
    </div>
  )
}

export default MainLayout