import React, { Suspense } from 'react';
import LottieHandler from '../LottieHandler/LottieHandler';


function PageSuspenseFallback({children}) {
  return (
    <Suspense fallback={
        <LottieHandler type="kite" message="يتم التحميل ..." />
      }>
          {children}

    </Suspense> 
  )
}

export default PageSuspenseFallback