import './App.css';

import { lazy } from 'react';

import PageSuspenseFallback from './components/PageSuspenseFallback/PageSuspenseFallback';
import ChangePass from './pages/ChangePass/ChangePass';

import MainPage  from './pages/Main_page/MainPage';
import { createBrowserRouter,RouterProvider,Navigate  } from 'react-router-dom';

import SalaryPage from './pages/SalaryPage/SalaryPage';
import VacationRequestEmp from './pages/VacationRequestEmp/VacationRequestEmp';

import EvaluationForEmployeeShowPage from './pages/EvaluationForEmployeeShowPage/EvaluationForEmployeeShowPage';



import NoficationsPage from './pages/NoficationsPage/NoficationsPage';

import Login from './pages/Login/Login';
import CheckPrivateRouteAuth from './components/CheckPrivateRouteAuth/CheckPrivateRouteAuth';
import MainLayout from './Layouts/MainLayout';

import PermissionWrapper from './checkPermissionsForPages';
import IsManagerWrapper from './checkIfUserIsManager';
import Unautherized from './pages/Unautherized/Unautherized';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import IsManagerOrHasPermissionWrapper from './checkIfUserIsManagerOrHasPermission';
import EvalDirectManagerByEmps from './pages/EvaluationManagmentAllPagesRelated/EvalDirectManagerPages/EvalDirectManagerByEmps/EvalDirectManagerByEmps';


const EvalDirectManagerStat = lazy(()=> import('./pages/EvaluationManagmentAllPagesRelated/EvalDirectManagerPages/EvalDirectManagerStat/EvalDirectManagerStat'));
const EmpArchives = lazy(()=> import('./pages/EmpArchives/MainEmpArchives/EmpArchives'));
const ManageRoles = lazy(()=> import('./pages/ManageRoles/ManageRoles'));
const ManagmentPunshmentsByHRs = lazy(()=> import('./pages/ManagmentPunshmentsByHRs/ManagmentPunshmentsByHRs'));
const UpdateAnOldJobPosition = lazy(()=> import('./pages/MangeJobPositionsAllRelatedPages/UpdateAnOldJobPosition/UpdateAnOldJobPosition'));
const CreateNewJobPosition = lazy(()=> import('./pages/MangeJobPositionsAllRelatedPages/CreateNewJobPosition/CreateNewJobPosition'));
const MangmentJobPositions = lazy(()=> import('./pages/MangeJobPositionsAllRelatedPages/MangmentJobPositions/MangmentJobPositions'));
const CreateNewDepartment = lazy(()=> import('./pages/MangeDepartmentsAllPagesRelated/CreateNewDepartment/CreateNewDepartment'));
const UpdateAnOldDepatment = lazy(()=> import('./pages/MangeDepartmentsAllPagesRelated/UpdateAnOldDepartment/UpdateAnOldDepatment'));
const ManagmentDepartments = lazy(()=> import('./pages/MangeDepartmentsAllPagesRelated/ManagementDepartments/ManagmentDepartments'));
const VacationStatistics = lazy(()=> import('./pages/VacationStatistics/VacationStatistics'));
const VacationManagerShow = lazy(()=> import('./pages/VacationManagerShow/VacationManagerShow'));
const UploadFiles = lazy(()=> import('./pages/UploadFiles/UploadFiles'));
const EmpsMangment = lazy(()=> import('./pages/EmpsMangment/EmpsMangment'));
const DepartmentEmps = lazy(()=> import('./pages/DepartmentEmps/DepartmentEmps'));
const VacationHRShow = lazy(()=> import('./pages/VacationHRShow/VacationHRShow'));
const EmpsForADepartmnet = lazy(()=> import('./pages/MangeDepartmentsAllPagesRelated/EmpsForADepartmnet/EmpsForADepartmnet'));
const VacationRequestEmpByManager = lazy(()=> import('./pages/ExitPermissionsPages/VacationRequestEmpByManager/VacationRequestEmpByManager'));
const ExitPermissionsGuardsPage = lazy(()=> import('./pages/ExitPermissionsPages/ExitPermissionsGuardsPage/ExitPermissionsGuardsPage'));
const ExitPermissionStatistics = lazy(()=> import("./pages/ExitPermissionsPages/ExitPermissionStatistics/ExitPermissionStatistics"));
const EvaluationStartForTestPeriodEmps = lazy(()=> import('./pages/EvaluationManagmentAllPagesRelated/EvaluationStartForTestPeriodEmps/EvaluationStartForTestPeriodEmps'));
const EvaluationRequestsWatingforHRManager = lazy(()=> import('./pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EvaluationRequestsWatingforHRManager/EvaluationRequestsWatingforHRManager'));
const EvaluationSettingsManagment = lazy(()=> import('./pages/EvaluationSettingsManagment/EvaluationSettingsManagment'));
const EvaluationDraftMainComp = lazy(()=> import('./pages/EvaluationManagmentAllPagesRelated/EvaluationManagmentDraftsPages/EvaluationDraftMainComp/EvaluationDraftMainComp'));
const EvaluationRequestsReturnedBackToTheEvaluationofficer = lazy(()=> import('./pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EvaluationRequestsReturnedBackToTheEvaluationofficer/EvaluationRequestsReturnedBackToTheEvaluationofficer'));
const EvaluationRequestsReturnedBackToTheManagersDep = lazy(()=> import('./pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EvaluationRequestsReturnedBackToTheManagersDep/EvaluationRequestsReturnedBackToTheManagersDep'));
const EvaluationRequestsWatingTheManagersDep = lazy(()=> import('./pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EvaluationRequestsWatingTheManagersDep/EvaluationRequestsWatingTheManagersDep'));
const EmpsWhoHaveBeenEvaluatedPage = lazy(()=> import('./pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EmpsWhoHaveBeenEvaluatedPage/EmpsWhoHaveBeenEvaluatedPage'));
const EvaluationStatisticsMainPageShow = lazy(()=> import('./pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EvaluationStatisticsMainPageShow/EvaluationStatisticsMainPageShow'));
const EvaluationRequestsWatingforEvaluationofficer = lazy(()=> import('./pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EvaluationRequestsWatingforEvaluationofficer/EvaluationRequestsWatingforEvaluationofficer'));
const EvaluationEmpFromManager = lazy(()=> import('./pages/EvaluationManagmentAllPagesRelated/EvaluationEmpFromManagerPages/EvaluationEmpFromManager/EvaluationEmpFromManager'));
const CreateNewCompanyInternalNum = lazy(()=> import('./pages/CompPhoneNumsManagment/CompInternalNumsComponents/CreateNewCompanyInternalNum/CreateNewCompanyInternalNum'));
const ChangeCompanyInternalNum = lazy(()=> import('./pages/CompPhoneNumsManagment/CompInternalNumsComponents/ChangeCompanyInternalNum/ChangeCompanyInternalNum'));
const CompInternalNums = lazy(()=> import('./pages/CompPhoneNumsManagment/CompInternalNumsComponents/CompInternalNums/CompInternalNums'));
const CreateNewUser = lazy(()=> import('./pages/CreateNewUser/CreateNewUser'));
const AddNewPunshment = lazy(()=> import('./components/PunshmentsComps/AddNewPunshment/AddNewPunshment'));





const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/emp" />
  },
  {
    path: '/emp',
    element: (
      <CheckPrivateRouteAuth>
     <MainLayout />
    </CheckPrivateRouteAuth>
    ),
  //   errorElement: <Error />,
    children: [
      {
        index: true,
        element:(
          <CheckPrivateRouteAuth>
          <MainPage />
        </CheckPrivateRouteAuth>
        )
      },
       {
        path: 'empmang',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_users']}>
            <PageSuspenseFallback>
              <EmpsMangment />
            </PageSuspenseFallback> 
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'change_pass',
        element: (
        <CheckPrivateRouteAuth>
            <ChangePass />
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'uploadfiles',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['import_salaries','update_users','update_job_positions','update_papers','update_insurances','update_departments']}>
           <PageSuspenseFallback>
             <UploadFiles />
           </PageSuspenseFallback> 
          </PermissionWrapper>
        </CheckPrivateRouteAuth>

        )
      },
      {
        path: 'archives_show',
        element: (
          <CheckPrivateRouteAuth>
            <PermissionWrapper requiredPermissions={['delete_users']}>
             <PageSuspenseFallback>
               <EmpArchives />
             </PageSuspenseFallback> 
           </PermissionWrapper>
        </CheckPrivateRouteAuth>

        )
      },
      {
        path: 'vacations_hr_show',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['change_vacation_status']}>
          <PageSuspenseFallback >
            <VacationHRShow />
          </PageSuspenseFallback>
          </PermissionWrapper>
        </CheckPrivateRouteAuth>

        )
      },
      {
        path: 'departmentemps',
        element: (
        <CheckPrivateRouteAuth>
          <IsManagerWrapper>
            <PageSuspenseFallback>
             <DepartmentEmps />
            </PageSuspenseFallback>
          </IsManagerWrapper>
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'salarypage',
        element: (
        <CheckPrivateRouteAuth>
           <SalaryPage />
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'vacationreq',
        element: (
        <CheckPrivateRouteAuth>
             <VacationRequestEmp />
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'vaca_req_by_manager',
        element: (
        <CheckPrivateRouteAuth>
          <IsManagerWrapper>
            <PageSuspenseFallback>
              <VacationRequestEmpByManager />
            </PageSuspenseFallback>
          </IsManagerWrapper>
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'vacationmang',
        element: (
          <CheckPrivateRouteAuth>
           <IsManagerWrapper>
            <PageSuspenseFallback>
              <VacationManagerShow />
            </PageSuspenseFallback>  
           </IsManagerWrapper>
         </CheckPrivateRouteAuth>

        )
      },
      {
        path: 'vaca_stat',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_vacation_statistics']}>
           <PageSuspenseFallback>
             <VacationStatistics />
           </PageSuspenseFallback>   
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'exist_per_stat',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_vacation_statistics']}>
            <PageSuspenseFallback>
              <ExitPermissionStatistics />
             </PageSuspenseFallback> 
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'exist_per_guards',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['door_officer']}>
            <PageSuspenseFallback>
              <ExitPermissionsGuardsPage />
            </PageSuspenseFallback> 
          </PermissionWrapper>
         </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'eval_emp',
        element: (
        <CheckPrivateRouteAuth>
               <EvaluationForEmployeeShowPage />
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'eval_manager_by_emp',
        element: (
          <CheckPrivateRouteAuth>
            {/* <PageSuspenseFallback></PageSuspenseFallback> */}
            <EvalDirectManagerByEmps />
          </CheckPrivateRouteAuth>
              
        )
      },
      {
        path: 'eval_directManager_stat',
        element: (
          <CheckPrivateRouteAuth>
            <PermissionWrapper requiredPermissions={['read_evaluation_manager_statistics']}>
            <PageSuspenseFallback> 
              <EvalDirectManagerStat />
            </PageSuspenseFallback>  
            </PermissionWrapper>
          </CheckPrivateRouteAuth>
              
        )
      },
      {
        path: 'mang_deps',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_departments']}>
            <PageSuspenseFallback>
              <ManagmentDepartments />
            </PageSuspenseFallback>  
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'create_dep',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['create_departments']}>
           <PageSuspenseFallback>
              <CreateNewDepartment />
           </PageSuspenseFallback>    
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'update_dep/:id',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['update_departments']}>
           <PageSuspenseFallback>
               <UpdateAnOldDepatment />
           </PageSuspenseFallback>     
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'show_emps_dep/:id',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_departments']}>
            <PageSuspenseFallback>
               <EmpsForADepartmnet />
            </PageSuspenseFallback>   
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'mang_job_positions',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_job_positions']}>
            <PageSuspenseFallback>
                <MangmentJobPositions />
            </PageSuspenseFallback>    
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'create_job_position',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['create_job_positions']}>
            <PageSuspenseFallback>
               <CreateNewJobPosition />
            </PageSuspenseFallback>   
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'update_job_position/:id',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['update_job_positions']}>
            <PageSuspenseFallback>
              <UpdateAnOldJobPosition />
            </PageSuspenseFallback>  
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'nofications',
        element: (
        <CheckPrivateRouteAuth>
                <NoficationsPage />
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'manage_roles',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_roles']}>
            <PageSuspenseFallback>
                 <ManageRoles/> 
            </PageSuspenseFallback>     
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'punshments_hr',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_penalties']}>
            <PageSuspenseFallback>
                <ManagmentPunshmentsByHRs /> 
           </PageSuspenseFallback>    
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },

      {
        path: 'addPunshment',
        element: (
          <CheckPrivateRouteAuth>
           <PermissionWrapper requiredPermissions={['create_penalties']}>
           <PageSuspenseFallback >
                <AddNewPunshment /> 
          </PageSuspenseFallback >
           </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'addNewUser',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['create_users']}>
            <PageSuspenseFallback>
                <CreateNewUser /> 
            </PageSuspenseFallback>   
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      ,
      {
        path: 'compInternalNums',
        element: (
          // <CheckPrivateRouteAuth>
          // <PermissionWrapper requiredPermissions={['read_penalties']}>
          <PageSuspenseFallback>
                <CompInternalNums /> 
          </PageSuspenseFallback>      
        //   </PermissionWrapper>
        // </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'create_internalNum',
        element: (
          // <CheckPrivateRouteAuth>
          // <PermissionWrapper requiredPermissions={['read_penalties']}>
          <PageSuspenseFallback>
                <CreateNewCompanyInternalNum /> 
          </PageSuspenseFallback>       
        //   </PermissionWrapper>
        // </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'update_interalNum/:id',
        element: (
          // <CheckPrivateRouteAuth>
          // <PermissionWrapper requiredPermissions={['update_job_positions']}>
              <ChangeCompanyInternalNum />
        //   </PermissionWrapper>
        // </CheckPrivateRouteAuth> 
        )
      },
      {
        path: 'eval_emp_manager',
        element: (
          <CheckPrivateRouteAuth>
          <IsManagerOrHasPermissionWrapper requiredPermissions={['hr_manager','read_evaluations','update_evaluations']}>
            <PageSuspenseFallback>
              <EvaluationEmpFromManager />
            </PageSuspenseFallback> 
          </IsManagerOrHasPermissionWrapper>
        </CheckPrivateRouteAuth> 
        )
      },
      {
        path: 'eval_emp_draft',
        element: (
        <CheckPrivateRouteAuth>
           {/* <PermissionWrapper requiredPermissions={['create_evaluations']}> */}
            <PageSuspenseFallback>
              <EvaluationDraftMainComp />
            </PageSuspenseFallback>  
          {/* </PermissionWrapper> */}
      </CheckPrivateRouteAuth> 

        )
      },
      {
        path: 'eval_stat_main_page_show',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_statistics']}>
            <PageSuspenseFallback>
              <EvaluationStatisticsMainPageShow />
            </PageSuspenseFallback>  
          </PermissionWrapper>
        </CheckPrivateRouteAuth> 
        
        )
      },

      {
        path: 'eval_stat_evalOfficer',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_statistics']}>
            <PageSuspenseFallback>
              <EvaluationRequestsWatingforEvaluationofficer />
            </PageSuspenseFallback>   
          </PermissionWrapper>
         </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_stat_evalOfficer_returnedBack',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_statistics']}>
            <PageSuspenseFallback>
              <EvaluationRequestsReturnedBackToTheEvaluationofficer />
            </PageSuspenseFallback>  
           </PermissionWrapper>
         </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_stat_emps_evaluated_before',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_statistics']}>
            <PageSuspenseFallback>
              <EmpsWhoHaveBeenEvaluatedPage />
            </PageSuspenseFallback>  
           </PermissionWrapper>
         </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_stat_requests4manger',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_statistics']}>
            <PageSuspenseFallback>
              <EvaluationRequestsWatingTheManagersDep />
            </PageSuspenseFallback>  
           </PermissionWrapper>
         </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_stat_requests4manger_returnedBack',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_statistics']}>
            <PageSuspenseFallback>
              <EvaluationRequestsReturnedBackToTheManagersDep />
            </PageSuspenseFallback>  
          </PermissionWrapper>
        </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_stat_requests4HRmanager',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_statistics']}>
            <PageSuspenseFallback>
              <EvaluationRequestsWatingforHRManager />
            </PageSuspenseFallback>  
           </PermissionWrapper>
         </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_settings',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_settings']}>
            <PageSuspenseFallback>
              <EvaluationSettingsManagment />
            </PageSuspenseFallback>  
          </PermissionWrapper>
        </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_start_testperiod_requests',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['create_evaluations']}>
            <PageSuspenseFallback>
              <EvaluationStartForTestPeriodEmps />
            </PageSuspenseFallback>  
          </PermissionWrapper>
        </CheckPrivateRouteAuth> 
        
        )
      },

    ]
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/not_authorized',
    element: <Unautherized/> // Or a proper "Not Authorized" component
  }
  ,

  // {
  //   path: '/register',
  //   element: <Register />
  // }
]);




function App() {

  const dispatch = useDispatch();


  const { status, error } = useSelector((state) => state.authme);

  const state = localStorage.getItem('state');

  useEffect(() => {

      // dispatch(fetchAuthMe());
      // console.log("fetching the auth me")
    
  }, [dispatch, state]);



  return (
    <div className="App">

   {console.log("the status now is ",status)}
   <RouterProvider router={router} />

    </div>
  );

}

export default App;
