import { forwardRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import "./DropdownButton.css";

const DropdownButton = forwardRef((props, ref) => {
  const { children, toggle, open } = props;

  return (
    <div
      onClick={toggle}
      className={`dropdown-btn ${open ? "button-open" : null}`}
      ref={ref}
    >
      <div className="btn_dropDown_children nav_links_white">{children}</div>

      <span 
          className="toggle-icon"
          onClick={(e) => {
            e.stopPropagation(); // Prevent parent click event
            toggle(); // Toggle state when clicking on the icon
          }}
      >
        {open ? <FaChevronUp className="dropdowm_icon"  /> : <FaChevronDown className="dropdowm_icon" />}
      </span>
    </div>
  );
});

export default DropdownButton;
