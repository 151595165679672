import "./successNotifiMainPage.scss"

function SuccessNotifiMainPage({successMessage}) {
  return (
    <div className="success_notif_cover">

    <span className="success_notif_text" dir="rtl">{successMessage}</span>
    
</div>
  )
}

export default SuccessNotifiMainPage