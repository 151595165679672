import React from 'react'

import './punchmentSectionForEmployee.scss'
import PopupGfg from '../../../pages/VacationManagerShow/VacationReasonPopup';


function PunchmentSectionForEmplouyee() {

    let objar =[ 
        {
          id:5955,
          name:"علاء الدين عاصي",
          cutPresent:"12%",
          resonOfPunsh:"لسبب ما قد تقرر ذلك",
          dateOfpunsh:"2024-6-27"
        },

     ]


     const TruncatedText = (text, maxLength ) => {
      const truncatedText = text.length > maxLength? `${text.split(' ')[0]}...` : text;
    
      return <>{truncatedText}</>
    };
    
    
       
         return (

            <div className="EvaluationForEmployeeShowPage_PunchmentSection">
           <span className='EvaluationForEmployeeShowPage_PunchmentSection_header' dir="rtl">معلومات العقوبة</span>

              <span className='forLaterWork' style={{textAlign:"center", padding:"1rem"}} dir='rtl' >قريبا ...</span>
            
 
            {
              objar.length === -1 ?
             
                objar.length === 0 ? 
         (<span className='EvaluationForEmployeeShowPage_PunchmentSection_noPunchment' dir="rtl">لا يوجد عليك عقوبات</span>)               
                :
         
         (<>
         <table className='punshmentForEmpShow_table'>
        <thead>
        <tr className='punshmentForEmpShow_header_row_table'>
          <th>تاريخ العقوبة</th>
          <th>سبب الخصم</th>
          <th>نسبة الخصم</th>
          <th>الرقم الوظيفي</th>
        </tr>
        </thead>
        
    <tbody>
          { objar.map(
            obj =>(
              <tr  key={obj.id}>
           <td dir="rtl">{obj.dateOfpunsh}</td>
          <td dir="rtl"><PopupGfg reason={obj.resonOfPunsh} firstWord = {TruncatedText(obj.resonOfPunsh,15)}/></td>
           {/* <td dir="rtl">{obj.resonOfPunsh}</td> */}
           <td dir="rtl">{obj.cutPresent}</td>
           <td dir="rtl">{obj.id}</td>
        </tr>

            )
          )}
        </tbody>

    </table>
       
        </>) 

        :
        (<></>)
            }

       
       </div>
         )
  
}

export default PunchmentSectionForEmplouyee