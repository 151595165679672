
import { useEffect, useState } from "react";

import axios from "axios";
import { errorHandeling } from "../../errorHandeling";
import { useSelector } from "react-redux";
import MainPageNoficationEval from "../MainPageNoficationEval/MainPageNoficationEval";

function ShouldEvalMyManager() {

    const token = useSelector(state => state.user.token)



   //to show the evaluation nofication
   const [evalNotifi,setEvalNotifi] = useState(false); 
   
   const [error,setError] = useState('');

  //fetch the data for the evaluation
 const fetchEvalouationData = async () => {

    try {

      const response = await axios.get(
        `https://emp.almatingroup.com/api/V1/manager-evaluations/should-evaluate-my-manager`,
     
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data);
      
      setEvalNotifi(response?.data?.data?.evaluate_my_manager);

  
    } catch (error) {
      console.error('Error:', error);
 
      setError(errorHandeling(error))
  
    }
  
  
  };


  useEffect(()=>{
    fetchEvalouationData();
  },[])


  return (
    <>
    {
        (evalNotifi) &&
        (
            <MainPageNoficationEval 
            notifText={"يرجى تقييم المدير المباشر المسؤول عنك"}
            btnText = {"تقييم"}
            btnLink = {"/emp/eval_manager_by_emp"}
            error = {error}
            />
        )
    }

    </>
  )
}

export default ShouldEvalMyManager