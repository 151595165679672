import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './directManagerEvalStatPopup.scss'
import { labelsArr, responseLabelsArr } from '../../libs/arrEvalDirectManager';
 
export default function DirectManagerEvalStatPopup({data,firstWord}) {

    const handleShowData = ()=>{
        return (
         <>
         {
            responseLabelsArr.map((item,index)=>(
            <div className='Eval_DirectManger_item_value' key={index}>
              <span dir='rtl' style={{color:"rgba(52,144,220,255)"}}>{index+1}-</span>
              <span dir='rtl'>{labelsArr[index]} : </span>
              <span dir='rtl'>{data[item] ? Math.round(data[item]) : ""}</span>   
            </div>
            ))
         }
         </>
         ) }


    return (
        <div>
          
            <Popup trigger=
                {  <span className='text_inPopUp' dir='rtl'>{firstWord}</span>}
                position="bottom center"
                on={['hover']} // Set the trigger to hover
                closeOnDocumentClick
                mouseEnterDelay={100} // Optional: Adds a slight delay before showing the popup
                mouseLeaveDelay={100} // Optional: Adds a slight delay before hiding the popup
                >
                <div className='Eval_DirectMangerPopup_div_container' dir='rtl'>
                 <div className='Eval_DirectMangerPopup_items'>
                    {handleShowData()}
                </div>   
                </div>
            </Popup>
        </div>
    )
};