
import { useEffect, useState } from "react";
import axios from "axios";
import { errorHandeling } from "../../errorHandeling";
import { useSelector } from "react-redux";
import MainPageNoficationEval from "../MainPageNoficationEval/MainPageNoficationEval";

function ShowManagerEvalNofication() {

    const token = useSelector(state => state.user.token)



   //to show the evaluation nofication
   const [evalNotifi,setEvalNotifi] = useState(false); 
   
   const [error,setError] = useState('');

  //fetch the data for the evaluation
 const fetchEvalouationData = async () => {

    try {
    //   setLoadingResult(true);
      const response = await axios.get(
        `https://emp.almatingroup.com/api/V1/evaluations/waitings?page=${1}`,
     
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data.data);
      if(response.data.data.data.length > 0 ){ //check if there is peoble to eval them
        setEvalNotifi(true);
      }
      else{
        setEvalNotifi(false);
      }
      // setLoadingResult(false);
    } catch (error) {
      console.error('Error:', error);
    //   setLoadingResult(false);
      setError(errorHandeling(error))
  
    }
  
  
  };


  useEffect(()=>{
    fetchEvalouationData();
  },[])


  return (
    <>
    {
        (evalNotifi) &&
        (
            <MainPageNoficationEval 
            notifText={"لديك بعض الموظفين بحاجة الى تقييم يرجى تقيمهم"}
            btnText = {"تقييم"}
            btnLink = {"/emp/eval_emp_manager"}
            error = {error}
            />
        )
    }

    </>
  )
}

export default ShowManagerEvalNofication