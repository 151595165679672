
import "./mainPageNoficationEval.scss";
import { useNavigate } from "react-router-dom";

function MainPageNoficationEval({notifText,btnText,btnLink,error}) {

    let navigate = useNavigate();


  const handelClickingEval = ()=>{
    navigate(btnLink);
  }

  return (
    <div className="eval_notif_cover">
        {
            (error) &&
            (<span style={{color:"red"}}>{error}</span>)
        }
        <span className="eval_notif_text" dir="rtl">{notifText}</span>
        
        <div className="eval_btn_cover">
        <button className="eval_notif_btn" dir="rtl" onClick={()=>{handelClickingEval()}}>{btnText}</button>
        </div>
    </div>
  )
}

export default MainPageNoficationEval