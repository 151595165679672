import React, { useEffect, useRef, useState } from 'react'
import './navbar.scss'
import { FaBell } from "react-icons/fa";
import almatin_img from '../../img/almatin_group.png'
import Dropdown from '../Dropdowns/Dropdown/Dropdown'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from '../../store/UserSlice/userSlice';
import  { clearAuthMe, fetchAuthMe } from '../../store/AuthmeSlice/authmeSlice';
import { createSelector } from 'reselect';

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);


function Navbar({ onIconClick }) {

  const userInfo = useSelector((state) => state.user.userInfo);

  const userPermissions = useSelector(selectUserPermissions);

  const authMeStatus = useSelector(state => state.authme.status);

  const authMe = useSelector(state => state.authme.data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sideNavRef = useRef(null);

  const [openNavIcon , setOpenNavIcon ] = useState(false)


  //to close the navbar when we click outside of it
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);


    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
      setOpenNavIcon(false);
    }
  }


  useEffect(() => {
    if (authMeStatus === 'idle') {
      dispatch(fetchAuthMe());
    }

  }, [dispatch, authMeStatus]);




//check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    return false;
  }
  else{
    return true;
  }
};








  const handelLogOut = (e)=>{
       // Clear local storage
       dispatch(clearAuthMe())
       dispatch(clearUser());

       const serializedState = localStorage.getItem('state');

       if (serializedState !== null) {
          localStorage.removeItem('state');
   
        }
       
       // Dispatch logout action to clear Redux state

   
       // Navigate to the login page or another page
       navigate('/login');
  }


//function to return the full emp name 
 const handleGetFullEmpName = ()=>{
  let fullName = "";
  if(userInfo.family_name){
    fullName = userInfo.name + ' ' + userInfo.family_name;
  }
  else{
    fullName = userInfo.name;
  }

  return fullName ;
 }





  return (
    <div className='navbar'>
      {
        (!authMe.change_password) 
        ?
        (
          <div className="navbar_left">

          <div className='big_navbar'>
   
         {/* <Link to="/emp/nofications" ><FaBell className='nav_bar_bell'/></Link> */}
   
   
   
         {( checkPermissionAndThrow("create_evaluations") || checkPermissionAndThrow("read_evaluations") || checkPermissionAndThrow("update_evaluations")
          || checkPermissionAndThrow("read_evaluation_settings") || checkPermissionAndThrow("read_manager_evaluations") 
          ||  checkPermissionAndThrow("hr_manager") || checkPermissionAndThrow("read_evaluation_manager_statistics")
          || checkPermissionAndThrow("read_evaluation_statistics") || authMe?.is_manager  ) &&
           (
   
           <Dropdown
             buttonText="التقييم"
             content={
               <>
                 { (authMe?.is_manager ||  checkPermissionAndThrow("hr_manager") || checkPermissionAndThrow("read_evaluations") 
                 || checkPermissionAndThrow("update_evaluations") )  && (
                   <Link to="/emp/eval_emp_manager" className='link_inside_dropdown' ><span dir="rtl"  >التقييم</span></Link>
                 )}
   
                 { ( checkPermissionAndThrow("read_evaluation_statistics") )  && (
                     <Link to="/emp/eval_stat_main_page_show" className='link_inside_dropdown' ><span dir="rtl"  >احصائيات تقييم الموظفين</span></Link>
                 )}

                 
                 { ( checkPermissionAndThrow("read_evaluation_manager_statistics") )  && (
                     <Link to="/emp/eval_directManager_stat" className='link_inside_dropdown' ><span dir="rtl"  >احصائيات تقييم المدير المباشر</span></Link>
                 )}
   
               
               { (checkPermissionAndThrow("read_evaluation_settings") )  && (
                       <Link to="/emp/eval_settings" className='link_inside_dropdown' ><span dir="rtl"  >اعدادات التقييم</span></Link>
                 )}
   
   
                { (checkPermissionAndThrow("create_evaluations") )  && (
                       <Link to="/emp/eval_start_testperiod_requests" className='link_inside_dropdown' ><span dir="rtl"  >بدء تقييم الفترة التجريبية </span></Link>
                 )}

               
               {/* <Link to="/emp" className='link_inside_dropdown' ><span dir="rtl"  >التصدير الى ملفات اكسل</span></Link> */}
    
    
                </>
             }
           />
               ) }
   
   
   
             {(
               checkPermissionAndThrow("read_users") || checkPermissionAndThrow("read_departments")
               || checkPermissionAndThrow("read_job_positions") || checkPermissionAndThrow("read_penalties") || checkPermissionAndThrow("read_roles")
               || checkPermissionAndThrow("update_penalties") || checkPermissionAndThrow("update_insurances") || checkPermissionAndThrow("update_users")
               || checkPermissionAndThrow("update_departments") || checkPermissionAndThrow("update_papers")  || checkPermissionAndThrow("update_job_positions") 
               || checkPermissionAndThrow("import_salaries") || checkPermissionAndThrow("delete_users") || checkPermissionAndThrow("read_vacation_statistics")
      
              ) && (
               
                 <Dropdown
                 buttonText="الادارة"
                 content={
                   <>
                   {(checkPermissionAndThrow("create_users") ) && (
                   <Link to="/emp/addNewUser" className='link_inside_dropdown'><span dir="rtl">انشاء موظف</span></Link>
                     )}
   
                     {(checkPermissionAndThrow("read_users") ) && (
                   <Link to="/emp/empmang" className='link_inside_dropdown'><span dir="rtl">ادارة الموظفين</span></Link>
                     )}
                     
                     {( checkPermissionAndThrow("read_vacation_statistics") ) && (
                   <Link to="/emp/vaca_stat" className='link_inside_dropdown'><span dir="rtl"  >احصائيات الاجازات</span></Link>
                     )}
   
                   {( checkPermissionAndThrow("read_vacation_statistics") ) && (
                   <Link to="/emp/exist_per_stat" className='link_inside_dropdown'><span dir="rtl"  >احصائيات اذونات الخروج</span></Link>
                     )}
   
                   {
                     (checkPermissionAndThrow("hr_manager") ) &&
                     (
                     ( checkPermissionAndThrow("door_officer") ) && (
                       <Link to="/emp/exist_per_guards" className='link_inside_dropdown'><span dir="rtl"  >اذونات الخروج</span></Link>
                   
                     ) )}
       
                   {(checkPermissionAndThrow("read_departments") ) && (
                   <Link to="/emp/mang_deps" className='link_inside_dropdown'><span dir="rtl"  >ادارة الاقسام</span></Link>
                   
                     )}
       
                   {(checkPermissionAndThrow("read_job_positions") ) && (
                    <Link to="/emp/mang_job_positions" className='link_inside_dropdown'><span dir="rtl"  >ادارة المناصب الوظيفية</span></Link>
                   
                     )}
       
                   {(checkPermissionAndThrow("read_penalties") ) && (
                     <Link to="/emp/punshments_hr" className='link_inside_dropdown'><span dir="rtl"  >ادارة العقوبات</span></Link>
                   
                     )}
       
                    {(checkPermissionAndThrow("read_roles") ) && (
                        <Link to="/emp/manage_roles" className='link_inside_dropdown'><span dir="rtl"  >ادارة الصلاحيات</span></Link>
                   
                     )}
   
                         
                     {( checkPermissionAndThrow("delete_users") ) && (
                          <Link to="/emp/archives_show" className='link_inside_dropdown'><span dir="rtl"  >ادارة الأرشيف</span></Link>
                   
                     )}
       
                     {(checkPermissionAndThrow("import_salaries") || checkPermissionAndThrow("update_users") || checkPermissionAndThrow("update_job_positions") 
                        || checkPermissionAndThrow("update_papers") || checkPermissionAndThrow("update_insurances") ||  checkPermissionAndThrow("update_departments") ) && (
                          <Link to="/emp/uploadfiles" className='link_inside_dropdown'><span dir="rtl"  >رفع الملفات</span></Link>
                   
                     )}
          
                    
                    </>
                 }
               />
   
                 )}
   
          {
           (authMe?.is_manager)
            &&
            (
             <Dropdown
             buttonText="القسم"
             content={
               <>
               <Link to="/emp/departmentemps" className='link_inside_dropdown'><span dir="rtl"  >موظفيين القسم</span></Link>

               {
                 ( !checkPermissionAndThrow("change_vacation_status") ) &&
                 (
                  <Link to="/emp/vacationmang" className='link_inside_dropdown'><span dir="rtl"  >طلبات الاجازة</span></Link>
                 )
               }

              
                </>
             }
           />
            )
           }
   
   
         {
            ( checkPermissionAndThrow("change_vacation_status") ) && (
                 <Link to="/emp/vacations_hr_show" className='nav_links_white'>طلبات الاجازة</Link>
                   
                ) }
   
   
   
            {
            (!checkPermissionAndThrow("hr_manager") ) &&
            (
            ( checkPermissionAndThrow("door_officer") ) && (
                 <Link to="/emp/exist_per_guards" className='nav_links_white'>أذونات الخروج</Link>
                   
                     ) )}
         
   
          <Link to="/emp/vacationreq" className='nav_links_white' >طلب اجازة</Link>
   
           <Link to="/emp/eval_emp" className='nav_links_white' >التقييم و المتابعة</Link>
           
           <Link to="/emp/salarypage" className='nav_links_white' >الراتب</Link>
   
           <Dropdown
             buttonText={handleGetFullEmpName()}
             content={
               <>
              <Link to="/emp" className='link_inside_dropdown'><span dir="rtl"  >الصفحة الرئيسية</span></Link>
              <Link to="/emp/change_pass" className='link_inside_dropdown'><span dir="rtl"  >تغيير كلمة المرور </span></Link>
              
              {/* <Link to="/emp" className='link_inside_dropdown'><span dir="rtl"  >تحميل تطبيق الأندرويد </span></Link> */}
               
                <span  onClick={(e)=>{handelLogOut(e)}} className='span_text_in_NavBar link_inside_dropdown' >تسجيل الخروج</span>
               </>
             }
           />
   
          </div>
   
          <div className='small_navbar' ref={sideNavRef} >
   
            <div className='small_navbar_icon_menu'  onClick={()=>{ setOpenNavIcon(!openNavIcon); console.log("nav icon ",openNavIcon) }}>
   
             <div className='menuIcon_item'></div>
             <div className='menuIcon_item'></div>
             <div className='menuIcon_item'></div>
   
            </div>
   
   
            <div className={openNavIcon ? "menu active" : "menu"} >
   
            <Dropdown
             buttonText={handleGetFullEmpName()}
             content={
               <>
              <Link to="/emp" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl" >الصفحة الرئيسية</span></Link>
              <Link to="/emp/change_pass" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }}><span dir="rtl"  >تغيير كلمة المرور </span></Link>
            
              {/* <Link to="/emp" className='link_inside_dropdown'><span dir="rtl"  >تحميل تطبيق الأندرويد </span></Link> */}
            
              <span  onClick={(e)=>{handelLogOut(e)}} className='span_text_in_NavBar link_inside_dropdown' >تسجيل الخروج</span>
   
               </>
             }
           />
   
           <Link to="/emp/salarypage" className='nav_links_white' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} >الراتب</Link>
   
           
           <Link to="/emp/eval_emp" className='nav_links_white' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} >التقييم و المتابعة</Link>
   
           <Link to="/emp/vacationreq" className='nav_links_white' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} >طلب اجازة</Link>
   
   
   
           {
            ( checkPermissionAndThrow("change_vacation_status") ) && (
                 <Link to="/emp/vacations_hr_show" className='nav_links_white' onClick={()=>{ setOpenNavIcon(!openNavIcon); }}>طلبات الاجازة</Link>
                   
                ) }
   
   
   
   
           {
            (!checkPermissionAndThrow("hr_manager") ) &&
            (
            ( checkPermissionAndThrow("door_officer") ) && (
                 <Link to="/emp/exist_per_guards" className='nav_links_white' onClick={()=>{ setOpenNavIcon(!openNavIcon); }}>أذونات الخروج</Link>
                   
                     ) )}
   
   
           
   
         {
           (authMe?.is_manager)
            &&
            (
             <Dropdown
             buttonText="القسم"
             content={
               <>
               <Link to="/emp/departmentemps" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >موظفيين القسم</span></Link>
                
                {
                  ( !checkPermissionAndThrow("change_vacation_status") ) && 
                  (
                    <Link to="/emp/vacationmang" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >طلبات الاجازة</span></Link>
                  )
                }

                </>
             }
           />
            )
           }
   
   
   
        {(
               checkPermissionAndThrow("read_users") ||  checkPermissionAndThrow("read_departments")
               || checkPermissionAndThrow("read_job_positions") || checkPermissionAndThrow("read_penalties") || checkPermissionAndThrow("read_roles")
               || checkPermissionAndThrow("update_penalties") || checkPermissionAndThrow("update_insurances") || checkPermissionAndThrow("update_users") 
               || checkPermissionAndThrow("update_papers") ||   checkPermissionAndThrow("update_departments") || checkPermissionAndThrow("update_job_positions") 
               || checkPermissionAndThrow("import_salaries") || checkPermissionAndThrow("delete_users") || checkPermissionAndThrow("read_vacation_statistics")
               ) && (
               
                 <Dropdown
                 buttonText="الادارة"
                 content={
                   <>
                   {(checkPermissionAndThrow("create_users") ) && (
                   <Link to="/emp/addNewUser" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl">انشاء موظف</span></Link>
                     )}
   
                     {(checkPermissionAndThrow("read_users") ) && (
                   <Link to="/emp/empmang" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl">ادارة الموظفين</span></Link>
                     )}
                     
                     {( checkPermissionAndThrow("read_vacation_statistics") ) && (
                   <Link to="/emp/vaca_stat" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >احصائيات الاجازات</span></Link>
                     )}
   
                    {( checkPermissionAndThrow("read_vacation_statistics") ) && (
                   <Link to="/emp/exist_per_stat" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >احصائيات اذونات الخروج</span></Link>
                     )}
   
                   {
                     (checkPermissionAndThrow("hr_manager") ) &&
                     (
                     ( checkPermissionAndThrow("door_officer") ) && (
                       <Link to="/emp/exist_per_guards" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >اذونات الخروج</span></Link>
                   
                     ) )}
       
                   {(checkPermissionAndThrow("read_departments") ) && (
                   <Link to="/emp/mang_deps" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >ادارة الاقسام</span></Link>
                   
                     )}
       
                   {(checkPermissionAndThrow("read_job_positions") ) && (
                    <Link to="/emp/mang_job_positions" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >ادارة المناصب الوظيفية</span></Link>
                   
                     )}
       
                   {(checkPermissionAndThrow("read_penalties") ) && (
                     <Link to="/emp/punshments_hr" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >ادارة العقوبات</span></Link>
                   
                     )}
       
                    {(checkPermissionAndThrow("read_roles") ) && (
                        <Link to="/emp/manage_roles" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >ادارة الصلاحيات</span></Link>
                   
                     )}
   
                     {( checkPermissionAndThrow("delete_users") ) && (
                         <Link to="/emp/archives_show" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >ادارة الأرشيف</span></Link>
                   
                     )}
       
                     {(checkPermissionAndThrow("import_salaries") || checkPermissionAndThrow("update_users") || checkPermissionAndThrow("update_job_positions") 
                        || checkPermissionAndThrow("update_papers") || checkPermissionAndThrow("update_insurances") ||  checkPermissionAndThrow("update_departments") )  && (
                          <Link to="/emp/uploadfiles" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >رفع الملفات</span></Link>
                   
                     )}
          
                    
                    </>
                 }
               />
   
                 )}
   
   
   
        { ( checkPermissionAndThrow("create_evaluations") || checkPermissionAndThrow("read_evaluations") || checkPermissionAndThrow("update_evaluations")
         || checkPermissionAndThrow("read_evaluation_settings") || checkPermissionAndThrow("read_manager_evaluations")
         ||  checkPermissionAndThrow("hr_manager") || checkPermissionAndThrow("read_evaluation_statistics")
         || checkPermissionAndThrow("read_evaluation_manager_statistics")   || authMe?.is_manager  ) &&
           (
   
           <Dropdown
             buttonText="التقييم"
             content={
               <>
                 { (authMe?.is_manager ||  checkPermissionAndThrow("hr_manager") || checkPermissionAndThrow("read_evaluations") || checkPermissionAndThrow("update_evaluations") )  && (
                   <Link to="/emp/eval_emp_manager" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >التقييم</span></Link>
                 )}
   
                 { ( checkPermissionAndThrow("read_evaluation_statistics") )  && (
                     <Link to="/emp/eval_stat_main_page_show" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >احصائيات تقييم الموظفين</span></Link>
                 )}

                { ( checkPermissionAndThrow("read_evaluation_manager_statistics") )  && (
                     <Link to="/emp/eval_directManager_stat" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >احصائيات تقييم المدير المباشر</span></Link>
                 )}
   
               
               { (checkPermissionAndThrow("read_evaluation_settings") )  && (
                       <Link to="/emp/eval_settings" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >اعدادات التقييم</span></Link>
                 )}
   
             
               { ( checkPermissionAndThrow("create_evaluations") )  && (
                       <Link to="/emp/eval_start_testperiod_requests" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >بدء تقييم الفترة التجريبية </span></Link>
                 )}
               
               {/* <Link to="/emp" className='link_inside_dropdown' onClick={()=>{ setOpenNavIcon(!openNavIcon); }} ><span dir="rtl"  >التصدير الى ملفات اكسل</span></Link> */}
    
    
                </>
             }
           />
               ) }
   
   
               
   
   
   
               </div>
   
          </div>
   
           </div>
        )
        :
        (
          <></>
        )
      }

        <div className="navbar_right" onClick={()=>{onIconClick()}}>
          <span>مجموعة المتين</span>
            <img src={almatin_img} alt="almatin_image" className='navbar_image' />
        </div>
    </div>
  )
}

export default Navbar