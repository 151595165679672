import axios from "axios";

export const errorHandeling = (error) => {
  if (axios.isAxiosError(error) && error.response) {
    const statusCode = error.response.status;

    if (statusCode === 500) {
      // Handle Internal Server Error (500)
      return "هناك خطأ في الخادم";
    }
    else if(statusCode === 401 ){
      // Handle the ununtherized error
      return "عملية غير مصرح بها";
    } 
    else if (statusCode === 422) {
      // Handle validation errors
      console.error("Axios error: ", error.response.data);

      const errors = error.response.data.errors;
      if (errors && typeof errors === "object") {
        // Extract all error messages from the errors object
        const errorMessages = Object.values(errors)
          .flat() // Flatten any arrays of messages
          .join(", "); // Combine them into a single string
        return `*${errorMessages}`;
      }

      return "*خطأ في البيانات المرسلة";
    } else {
      // Handle other types of errors
      return "*خطأ غير متوقع يرجى المحاولة لاحقا";
    }
  } else {
    console.error("Unexpected error: ", error);
    return "*خطأ غير متوقع يرجى المحاولة لاحقا";
  }
};



