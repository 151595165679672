import React from 'react'

import './evalSectionForEmployee.scss'
import { useDispatch, useSelector } from 'react-redux';
import DirectManagerShowEval from '../../DirectManagerShowEval/DirectManagerShowEval';

function EvalSectionForEmoloyee() {

  const authMe = useSelector((state) => state.authme.data);

  return (
    <div className="EvaluationForEmployeeShowPage_evalSection">
    <span className='EvaluationForEmployeeShowPage_evalSection_header' dir="rtl">معلومات التقييم</span>
    <div className='EvaluationForEmployeeShowPage_evalSection_items'>
        <div className='EvaluationForEmployeeShowPage_evalSection_item'>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">{authMe.job_id}</span>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">الرقم الوظيفي: </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_evalSection_item'>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">{authMe.evaluation?.avg_marks}</span>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">درجة التقييم النهائي: : </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_evalSection_item'>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">{authMe.evaluation?.m_total_marks}</span>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">اخر تقييم للمدير المباشر : </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_evalSection_item'>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">{authMe.evaluation?.hr_total_marks}</span>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">اخر تقييم للموارد البشرية: </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_evalSection_item'>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">{authMe.evaluation?.m_strengths}</span>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">نقاط القوة: </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_evalSection_item'>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">{authMe.evaluation?.m_weaknesses}</span>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">نقاط الضعف: </span>
        </div>
        
        {
          (authMe?.is_manager ) &&
          (
            <DirectManagerShowEval/>
          )
        }


    </div>
</div>

  )
}

export default EvalSectionForEmoloyee