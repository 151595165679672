import React, { useEffect, useState } from 'react'
import './vacationRequestEmp.scss'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { TailSpin } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'




// const selectUserPermissions = createSelector(
//   state => state.authme.data?.roles[0].permessions,
//   permessions => permessions ? permessions.map(perm => perm.name) : []
// );






function VacationRequestEmp() {


  const token = useSelector(state => state.user.token)


  const authMe = useSelector(state => state.authme.data);
  
  const navigate = useNavigate();


    // Function to get the current time in "HH:MM" format
    const getCurrentTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0'); // Pad to ensure two digits
      const minutes = String(now.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    };


  const [startingDateVacField , setStartingDateVacField] = useState(new Date().toISOString().split('T')[0] )

  const [endingDateVacField , setEndingDateVacField] = useState(new Date().toISOString().split('T')[0] )

  const [exitPermissionDayField , setExitPermissionDayField] = useState(new Date().toISOString().split('T')[0] )
  

  const [reason,setReason] = useState("")

  const [loading ,setLoading] = useState(false)

  const [dateError, setDateError] = useState(null);   


  const [errorResponsable,setErrorResponsable] = useState("")


  const[vacationType,setVacationType] = useState("DEFAULT");

  const [existPermissionTime , setExistPermissionTime] = useState(getCurrentTime());

  const [missionStartTime , setMissionStartTime] = useState(getCurrentTime());

  const [missionEndTime , setMissionEndTime] = useState(getCurrentTime());

  const [waitingVacationId , setWaitingVacationId] = useState(-1);

  const [waitingVacationStatus , setWaitingVacationStatus] = useState("WAITING_FOR_HR_ACCEPT");


  // const [waitingVacationBool , setWaitingVacationBool] = useState(false);


  // const [authMeData , setAuthMeData] = useState({})

  const [loadingAuthMe,setLoadingAuthMe] = useState(false)

  const [error,setError] = useState(null);


  //check if the authme waitings contain any waiting vacation
  const checkForWaitingVaca = (arr)=>{
    setWaitingVacationId(-1);

    for(let i = 0 ; i < arr.length ; i++){
      let endDate = "" ;
      
      if(arr[i].type === "EXIT_PERMISSION"){
         endDate = arr[i].start_date ;
      }else{
        endDate = arr[i].end_date ;
      }

      let today = new Date();
      let endDateComp = new Date(endDate);
  
      // Strip time info from both dates
      today.setHours(0, 0, 0, 0);
      endDateComp.setHours(0, 0, 0, 0);

        // Compare dates
       if(endDateComp >= today){
        setWaitingVacationId(arr[i].id);
        setWaitingVacationStatus(vacationStatusCheck(arr[i].status))
        return ; 
       };

      // if(arr[i].status === "WAITING_FOR_MANAGER_ACCEPT" || arr[i].status === "WAITING_FOR_HR_ACCEPT"){
      //   setWaitingVacationId(arr[i].id);
      //   setWaitingVacationStatus(vacationStatusCheck(arr[i].status))
      //   // setWaitingVacationBool(true);
      //   return ; 
      // }
    }

  }


    //check the status of the vacation
    const vacationStatusCheck = (vacaStatus)=>{
      if( vacaStatus === "WAITING_FOR_MANAGER_ACCEPT" )
          return "بانتظار موافقة مدير القسم";
      else if( vacaStatus === "WAITING_FOR_HR_ACCEPT" )
          return "بانتظار موافقة الموارد البشرية";
      else if( vacaStatus === "WAITING_FOR_DOOR_OFFICER_ACCEPT" )
        return "بانتظار موافقة الحرس";
      else if( vacaStatus === "ACCEPTED" )
          return "تم الموافقة عليها";
  
    }


  const fetchAuthmeTemp = async()=>{
    setLoadingAuthMe(true);
      try {
          const response = await axios.post('https://emp.almatingroup.com/api/v1/auth/me', 
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
          console.log('the authme for the salary:',response.data.data)
          checkForWaitingVaca(response.data.data.waiting_vacations ? response.data.data.waiting_vacations : [] )
          setLoadingAuthMe(false);
        } catch (error) {
          setLoadingAuthMe(false);
          setError(error.message);
        }
  }


  useEffect(()=>{
      fetchAuthmeTemp();
  },[])



 useEffect(()=>{

 
  let validateDate = ()=>{

    // Convert string dates to Date objects for comparison
    const startDate = new Date(startingDateVacField);
    const endDate = new Date(endingDateVacField);
    const exitPermissionDay = new Date(exitPermissionDayField);

    const today = new Date();
    
  // Set the time part of the dates to 00:00:00 for comparison
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  exitPermissionDay.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);


    console.log("the today is :",today)
    console.log("the starting date is :",startDate)





    // Check if the starting date is after the ending date
    if (startDate > endDate) {
      setDateError(" *تاريخ البداية يجب ان يكون اصغر من تاريخ نهاية الاجازة");
      setErrorResponsable("date_end")
      return; // Prevent further execution
    }
    else if(startDate < today){
      setDateError("*تاريخ بداية الاجازة يجب أن يحدد ابتداءا من اليوم  وما بعده");
      setErrorResponsable("date_start")
      return; // Prevent further execution
    }
    else if(exitPermissionDay < today){
      setDateError("*تاريخ بداية الاجازة يجب أن يحدد ابتداءا من اليوم  وما بعده");
      setErrorResponsable("date_exitPermission")
      return; // Prevent further execution
    }
    else {
      setDateError(""); // Clear any previous errors
      // Proceed with form submission logic here
      setErrorResponsable("")


    }


}


  validateDate();

 },[endingDateVacField,startingDateVacField,exitPermissionDayField ])


 let StartingDateVacFieldFunc = (e) => {
    setStartingDateVacField(e.target.value);

  }

  let EndingDateVacFieldFunc = (e) => {
    setEndingDateVacField(e.target.value);

  }





  useEffect(() => {
    if (vacationType === "DEATH" || vacationType === "MARRIAGE") {
      const startDate = new Date(startingDateVacField);
      startDate.setDate(startDate.getDate() + 7);
      const formattedEndDate = startDate.toISOString().split('T')[0];
      setEndingDateVacField(formattedEndDate);
    }
  }, [vacationType, startingDateVacField]);



  const handleSubmit = async (e)=>{
    e.preventDefault();


    console.log("we are submitting the data")
    if(reason?.length !== 0){

    let start_date = 0;
    let end_date  = 0 ;

    if(vacationType ==="EXIT_PERMISSION"){
      start_date = `${exitPermissionDayField} ${existPermissionTime}:00`;
      end_date = "";
    }
    else if(vacationType ==="MISSION"){
      start_date = `${startingDateVacField} ${missionStartTime}:00`;
      end_date = `${endingDateVacField} ${missionEndTime}:00`;
    }
    else{
      start_date = startingDateVacField;
      end_date = endingDateVacField;
    }

    let data = {
        job_id : authMe.job_id,
        type : vacationType ,
        start_date : start_date,
        end_date : end_date ,
        reasons : reason
       
    } 

    

    console.log("the data is after forming it:",data)
 
 
 
    try {
      setLoading(true)
  
      const response = await axios.post(
        `https://emp.almatingroup.com/api/V1/vacations`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response after submitting:', response.data);
      setLoading(false)

      setErrorResponsable("")
      setDateError(null)
      setReason("")
      navigate("/emp");
      
  
    } catch (error) {
      console.error('Error:', error);
      setLoading(false)
      if(error.response?.data?.message === "ERR_01"){
        setDateError("*هنالك طلب اجازة سابق يتعارض مع طلب الاجازة الحالي")
      }
      else{
        setDateError(error.message && error.message)
      }

  
    }

 
  }
  else{
    setDateError("*يجب أن تحدد سبب الاجازة قبل ارسال الطلب")
    setErrorResponsable("reason")
  }


  }




//for canceling the vacation if there was any vacation in the waiting status
  const handleCancelVacation = async ()=>{

    console.log("we are submitting the data")
 
 
 
    try {
      setLoading(true)
  
      const response = await axios.delete(
        `https://emp.almatingroup.com/api/V1/vacations/${waitingVacationId}/cancel`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response after submitting:', response.data);
      setLoading(false)

      setErrorResponsable("")
      setDateError(null)
      setReason("")
      fetchAuthmeTemp();
      
  
    } catch (error) {
      console.error('Error:', error);
      setLoading(false)
      setError(error.message)

  
    }

  }


  
  //function to return the full emp name 
  const handleGetFullEmpName = (obj)=>{
    let fullName = "";
    if(obj.family_name){
      fullName = obj.name + ' ' + obj.family_name;
    }
    else{
      fullName = obj.name;
    }
  
    return fullName ;
   }
  


  return (
    <div className='vacationRequsetEmp'>

      { error && 
      <div className='error_show_vacation_Canceling'>
         <span className='error_show_vacation_Canceling_text' dir='rtl'>{error}</span>
      </div>
     
      }

     {
          loadingAuthMe ?
                  <div style={{width:"100%",display:"flex",alignItems:"cneter",justifyContent:"center"}} className='forLoadingVacaEmpReq'>
                 <TailSpin
                 visible={true}
                 height="40"
                 width="40"
                  color="rgb(246,229,56)"
                 ariaLabel="tail-spin-loading"
                 radius="1"
                 wrapperStyle={{}}
                 wrapperClass=""
                 />
                  </div>
                  : <>


      {
        waitingVacationId !== -1 ?
        (      <div className='vacationRequsetEmp_vacation_canceling'>
          <div className='vacationRequsetEmp_vacation_canceling_text_cover' >
          <span className='vacationRequsetEmp_vacation_canceling_text' dir='rtl'>تم ارسال طلب الاجازة , {waitingVacationStatus} </span>
          </div>
          {
            (waitingVacationStatus !== "تم الموافقة عليها")  &&
            <button className='vacationRequsetEmp_vacation_canceling_btn' onClick={()=>{handleCancelVacation()}} disabled={loading} dir='rtl'>{ loading ? "يتم المعالجة ..." :  "الغاء طلب الاجازة"}</button>
          }
    
       
        </div>)
        :
        (        <form className="vacationRequestEmp_container" onSubmit={handleSubmit}>
          <span className='vacationRequestEmp_container_title' dir="rtl">طلب اجازة</span>

          <div className="vacationRequestEmp_container_item">
              <span className='vacationRequestEmp_container_item_title' dir="rtl">الرقم الوظيفي</span>
              <span  className='vacationRequestEmp_container_item_info' dir="rtl">{authMe?.job_id}</span>
          </div>
          <div className="vacationRequestEmp_container_item">
              <span className='vacationRequestEmp_container_item_title' dir="rtl">المسؤول المباشر</span>
              <span  className='vacationRequestEmp_container_item_info' dir="rtl">{authMe?.direct_manager ? handleGetFullEmpName(authMe?.direct_manager) : "لم يتم التحديد"}</span>
          </div>

          <div className="vacationRequestEmp_changeResultType_select">
           <label className='vacationRequestEmp_changeResultType_select_label' dir="rtl">نوع الاجازة :</label>
           <select dir="rtl" className='vacationRequestEmp_changaType_selection' value={vacationType} name='vacationType' onChange={(e)=>{setVacationType(e.target.value)}}>
           <option value="DEFAULT" className='vacationRequestEmp_changaType_selection' dir="rtl">اجازة عادية</option>
           <option value="EXIT_PERMISSION" className='vacationRequestEmp_changaType_selection' dir="rtl">اذن الخروج</option>
           <option value="DEATH" className='vacationRequestEmp_changaType_selection' dir="rtl">اجازة وفاة</option>
           <option value="MARRIAGE" className='vacationRequestEmp_changaType_selection' dir="rtl">اجازة زواج</option>
           <option value="MISSION" className='vacationRequestEmp_changaType_selection' dir="rtl">مهمة</option>

           </select>
         </div>

         {//for the note
            (vacationType !== "DEFAULT") &&
            (
              <span className='vacationRequestEmp_changeResultType_Note' dir='rtl'>
              {
                (vacationType === "DEATH" || vacationType === "MARRIAGE") ?
                (<>
                  <span className='vacationRequestEmp_changeResultType_Note_label'>*ملاحظة:</span>
                  عدد أيام الاجازة مدفوعة الاجر الخاص بهذا النوع من الاجازة هو 7 أيام 
                </>
                )
                :
                (vacationType === "EXIT_PERMISSION") ?
                (<>
                  <span className='vacationRequestEmp_changeResultType_Note_label'>*ملاحظة:</span>
                  يجب تحديد اليوم و الوقت الخاص باذن الخروج
                </>
                )
                :
                <></>
  
              }
  
             </span>
            )
         }



        {
          (vacationType !== "EXIT_PERMISSION") ?
         (
            (vacationType !== "MISSION") ?
            (
              <div className='vacationRequestEmp_container_date'>
              <div className="vacationRequestEmp_container_date_item">
                  <span className='vacationRequestEmp_container_date_item_title' dir="rtl">تاريخ البداية</span>
                  <input type="date" dir="rtl"  className={errorResponsable === "date_start" ? 'vacationRequestEmp_container_date_item_info vacationRequestEmp_container_date_item_info_errorDate':'vacationRequestEmp_container_date_item_info'} value={startingDateVacField} onChange={(e)=>StartingDateVacFieldFunc(e)} />
              </div>
               <div className="vacationRequestEmp_container_date_item">
                  <span className='vacationRequestEmp_container_date_item_title' dir="rtl">تاريخ النهاية</span>
                  <input type="date" dir="rtl"  className={errorResponsable === "date_end" ? 'vacationRequestEmp_container_date_item_info vacationRequestEmp_container_date_item_info_errorDate':'vacationRequestEmp_container_date_item_info'} value={endingDateVacField} onChange={(e)=>EndingDateVacFieldFunc(e)} />
              </div>
              </div>
  
            )
            :
            <>
            
            <div className='vacationRequestEmp_container_misson_place'>
              <div className="vacationRequestEmp_container_misson_place_item">
                  <span className='vacationRequestEmp_container_misson_place_item_title' dir="rtl">تاريخ البداية</span>
                  <input type="date" dir="rtl"  className={errorResponsable === "date_start" ? 'vacationRequestEmp_container_misson_place_item_info vacationRequestEmp_container_misson_place_item_info_errorDate':'vacationRequestEmp_container_misson_place_item_info'} value={startingDateVacField} onChange={(e)=>StartingDateVacFieldFunc(e)} />
              </div>
               <div className="vacationRequestEmp_container_misson_place_item">
                  <span className='vacationRequestEmp_container_misson_place_item_title' dir="rtl">تاريخ النهاية</span>
                  <input type="date" dir="rtl"  className={errorResponsable === "date_start" ? 'vacationRequestEmp_container_misson_place_item_info vacationRequestEmp_container_misson_place_item_info_errorDate':'vacationRequestEmp_container_misson_place_item_info'} value={endingDateVacField} onChange={(e)=>EndingDateVacFieldFunc(e)} />
              </div>
              <div className="vacationRequestEmp_container_misson_place_item">
                <span className='vacationRequestEmp_container_misson_place_item_title' dir="rtl">توقيت البداية </span>
                <input type="time" dir="rtl"  className={errorResponsable === "date_start" ? 'vacationRequestEmp_container_misson_place_item_info vacationRequestEmp_container_misson_place_item_info_errorDate':'vacationRequestEmp_container_misson_place_item_info'} value={missionStartTime} onChange={(e)=>setMissionStartTime(e.target.value)} />
            </div>
            <div className="vacationRequestEmp_container_misson_place_item">
                <span className='vacationRequestEmp_container_misson_place_item_title' dir="rtl">توقيت النهاية</span>
                <input type="time" dir="rtl"  className={errorResponsable === "date_start" ? 'vacationRequestEmp_container_misson_place_item_info vacationRequestEmp_container_misson_place_item_info_errorDate':'vacationRequestEmp_container_misson_place_item_info'} value={missionEndTime} onChange={(e)=>setMissionEndTime(e.target.value)} />
            </div>
              </div>

            </>
         )
 
          
          :
          (
            <div className='vacationRequestEmp_exitPermission_date'>
             <div className="vacationRequestEmp_exitPermission_date_item">
                <span className='vacationRequestEmp_exitPermission_date_item_title' dir="rtl">اليوم </span>
                <input type="date" dir="rtl"  className={errorResponsable === "date_exitPermission" ? 'vacationRequestEmp_exitPermission_date_item_info vacationRequestEmp_exitPermission_date_item_info_errorDate':'vacationRequestEmp_exitPermission_date_item_info'} value={exitPermissionDayField} onChange={(e)=>setExitPermissionDayField(e.target.value)} />
            </div>
             <div className="vacationRequestEmp_exitPermission_date_item">
                <span className='vacationRequestEmp_exitPermission_date_item_title' dir="rtl">التوقيت</span>
                <input type="time" dir="rtl"  className={errorResponsable === "date_end" ? 'vacationRequestEmp_exitPermission_date_item_info vacationRequestEmp_exitPermission_date_item_info_errorDate':'vacationRequestEmp_exitPermission_date_item_info'} value={existPermissionTime} onChange={(e)=>setExistPermissionTime(e.target.value)} />
            </div>
            </div>
          )
        }


          {dateError && <p className='vacationRequestEmp_errorInDateMessage' dir="rtl">{dateError}</p>} 

          <div className="vacationRequestEmp_container_item">
              <span className='vacationRequestEmp_container_item_title' dir="rtl">الاسباب</span>
              <textarea  className={errorResponsable === "reason" ? "vacationRequestEmp_container_item_textarea vacationRequestEmp_container_item_textarea_errorReason" : "vacationRequestEmp_container_item_textarea" } rows="6" cols="50" dir="rtl" onChange={(e)=>{setReason(e.target.value)}}/>
          </div>

      
     { (authMe?.direct_manager) ?
         (<>
          <button type='submit' className='vacationRequestEmp_container_btn' disabled={errorResponsable === "date_start" || errorResponsable === "date_end" } dir="rtl">
          {
         loading ? 
         <>
        <TailSpin
        visible={true}
        height="22"
        width="22"
        color="white"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        />
       </>
      :
      <>أرسل الطلب</>
      }
         </button>
         </>)
            :
            (<span dir='rtl' style={{padding:"1rem",fontSize:"1rem"}} ><span style={{color:"red",fontSize:"1rem"}}>*</span>لم يتم تحديد مسؤولك المباشر يرجى مراجعة الموارد البشرية</span>)
         }
      


      </form>
      )
      }


        </> } 
    </div>
  )
}

export default VacationRequestEmp